import { observable, action, computed, ObservableMap } from 'mobx';
import { ChangeEvent } from 'react';

import RootStore from 'stores/RootStore';

import { CareProvidersListItem } from './types';
import {
  filterCareUnits,
  getInitialExpandedRowKeys,
  traverseCareProviderTreeAndAddCareunitPermissions,
} from '../careProviderUtils';

export default class CareProvidersListStore {
  @observable searchTerm = '';
  @observable expandedCareProvidersMap: ObservableMap<string, CareProvidersListItem[]> =
    new ObservableMap();
  @observable expandedRowKeys = observable.set<string>();

  constructor(private rootStore: RootStore) {}

  @action
  init() {
    this.expandedRowKeys.replace(
      new Set(
        getInitialExpandedRowKeys(
          this.careProviders,
          this.rootStore.userPermissionsStore.canViewCareUnit,
          this.rootStore.userPermissionsStore.canEditCareProvider
        )
      )
    );
  }

  @computed
  get careProvidersList() {
    if (!this.searchTerm) {
      return this.careProviders;
    }

    const matchingCareUnitsMap = this.buildMatchingCareUnitsMap();
    const searchTerm = this.searchTerm.trim();
    return this.careProviders
      .filter(
        item =>
          // preserve the careProvider, if at least one of its care units matches the search term
          matchingCareUnitsMap.get(item.id) ||
          // or the careProvider itself matches the term
          item.name.toLowerCase().includes(searchTerm) ||
          item.id.toLowerCase().includes(searchTerm) ||
          item.careUnits?.some(
            careUnit =>
              `${item.name} ${careUnit.name}`.toLowerCase().includes(searchTerm) ||
              careUnit.externalOrgId.toLowerCase().includes(searchTerm)
          )
      )
      .map(item => {
        // if there are matching care units, add only those to the children prop
        if (matchingCareUnitsMap.get(item.id)?.length) {
          return {
            ...item,
            children: matchingCareUnitsMap.get(item.id),
          };
        }

        return { ...item, careUnits: filterCareUnits(item.careUnits, searchTerm, item.name) };
      });
  }

  @computed
  get careProviderListWithPermissionsAttached() {
    return traverseCareProviderTreeAndAddCareunitPermissions(
      this.careProvidersList,
      this.rootStore.userPermissionsStore.canViewCareUnit,
      this.rootStore.userPermissionsStore.canEditCareProvider
    );
  }

  @computed
  get getCareProviderListWithPermissionsAccessibleToUser() {
    return traverseCareProviderTreeAndAddCareunitPermissions(
      this.careProvidersList,
      this.rootStore.userPermissionsStore.canViewCareUnit,
      this.rootStore.userPermissionsStore.canEditCareProvider,
      true //filter true
    );
  }

  private buildMatchingCareUnitsMap = () => {
    return new Map(
      Array.from(this.expandedCareProvidersMap).filter(expandedCareProvider => {
        expandedCareProvider[1] = expandedCareProvider[1].filter(
          item =>
            item.name.toLowerCase().includes(this.searchTerm) ||
            item.id.toLowerCase().includes(this.searchTerm)
        );

        return expandedCareProvider[1].length > 0;
      })
    );
  };

  @computed
  get careProviders() {
    return this.rootStore.partnersStore.careProviders.map(careProvider => ({
      ...careProvider,
      name: careProvider.name || '',
      children: this.expandedCareProvidersMap.get(careProvider.id) || [],
    }));
  }

  @action
  handleSearchChange = (value: string) => {
    this.searchTerm = value.toLowerCase();
  };

  handleExpand = (expanded: boolean, careProviderId: string) => {
    this.setExpandedRowKeys(expanded, careProviderId);
  };

  @action
  setExpandedRowKeys = (expanded: boolean, careProviderId: string) => {
    if (expanded) {
      this.expandedRowKeys.add(careProviderId);
    } else {
      this.expandedRowKeys.delete(careProviderId);
    }
  };
}

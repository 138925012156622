import axios from 'axios';

import { HasUUID } from 'types/types';
import { fetchCollectionWithUUID, createWithUUID, updateWithUUID } from 'utils/apiUtils';

export interface NewsItem extends HasUUID {
  subject: string;
  body: string;
  link: string;
}

// eslint-disable-next-line @platform24/manage-ui/enforce-admin-service-urls
export const NEWS_ITEMS_URI = '/rest/healthmanager/admin/newsitems/v1';

export const fetchNewsItems = (originId: string) =>
  fetchCollectionWithUUID<NewsItem>(`${NEWS_ITEMS_URI}/${originId}`);

export const createNewsItem = async (data: NewsItem, originId: string) =>
  createWithUUID<NewsItem>(`${NEWS_ITEMS_URI}/${originId}`, data);

export const updateNewsItems = (data: NewsItem[], originId: string) =>
  updateWithUUID<NewsItem>(`${NEWS_ITEMS_URI}/${originId}`, data);

export const deleteNewsItem = (id: string, originId: string) =>
  axios.delete(`${NEWS_ITEMS_URI}/${originId}/${id}`);

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { AnnouncementsByCareProviderDTO, CreateAnnouncementDTO } from './types';

export const useGetAnnouncementsForCareProvider = (careProviderId?: string) => {
  return useQuery(
    ['announcements', 'care-provider', careProviderId],
    async () => {
      const response = await axios.get<AnnouncementsByCareProviderDTO>(
        // eslint-disable-next-line @platform24/manage-ui/enforce-admin-service-urls
        `/rest/clinic-backend/clinic/announcement/v1/by-care-provider/${careProviderId}`
      );
      return response;
    },
    {
      enabled: Boolean(careProviderId),
    }
  );
};

/**
 * BE knows if it's a care provider or care unit announcement based on the presence of careProviderId / careUnitId
 */
export const useCreateAnnouncement = () => {
  const queryClient = useQueryClient();
  return useMutation(async (data: CreateAnnouncementDTO) => {
    // eslint-disable-next-line @platform24/manage-ui/enforce-admin-service-urls
    const response = await axios.post(`/rest/clinic-backend/clinic/announcement/v1`, data);
    queryClient.invalidateQueries(['announcements']);

    return response;
  });
};

export const useDeleteAnnouncement = () => {
  const queryClient = useQueryClient();
  return useMutation(async (announcementId: string) => {
    const response = await axios.delete(
      // eslint-disable-next-line @platform24/manage-ui/enforce-admin-service-urls
      `/rest/clinic-backend/clinic/announcement/v1/${announcementId}`
    );
    queryClient.invalidateQueries(['announcements']);

    return response;
  });
};

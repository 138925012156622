import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import {
  ConnectDragSource,
  ConnectDropTarget,
  DragSource,
  DropTarget,
  DropTargetMonitor,
} from 'react-dnd';

import styles from './DraggableRow.module.css';

let dragingIndex = -1;

type MoveRow = (dragIndex: number, hoverIndex: number, parent?: any) => void;

interface Props {
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  isOver: boolean;
  canDrop: boolean;
  moveRow: MoveRow;
  index: number;
  style: React.CSSProperties;
  className: string;
  // If dragSection is provided, drag and drop actions will be restricted to
  // same dragSection items
  dragSection?: string;
  isDraggable?: boolean;
}

const BodyRow: FunctionComponent<Props> = ({
  isOver,
  canDrop,
  connectDragSource,
  connectDropTarget,
  moveRow,
  isDraggable,
  dragSection,
  ...restProps
}) => {
  const style = { ...restProps.style };
  let { className } = restProps;

  if (isDraggable !== false) {
    style.cursor = 'move';
  }

  if (isOver && canDrop) {
    if (restProps.index > dragingIndex) {
      className = classNames(className, styles.dropOverDownward);
    }
    if (restProps.index < dragingIndex) {
      className = classNames(className, styles.dropOverUpward);
    }
  }

  return connectDragSource(
    // @ts-ignore CSS type mismatch
    connectDropTarget(<tr {...restProps} className={className} style={style} />)
  );
};

const rowSource = {
  canDrag(props: { dragSection?: string; isDraggable?: boolean }) {
    return props.isDraggable !== false;
  },
  beginDrag(props: { index: any; dragSection?: string }) {
    dragingIndex = props.index;
    return {
      index: props.index,
      dragSection: props.dragSection,
    };
  },
};

const rowTarget = {
  drop(
    props: { index: number; moveRow: MoveRow; dragSection?: string },
    monitor: DropTargetMonitor
  ) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Time to actually perform the action
    // Please note, that both dragIndex and hoverIndex values depends on the number of visible rows.
    // In case of expandable rows, they can differ. For example, if we have two expandable rows
    // with 4 child rows each, index of the last item in second row can be either 5 (only second row expanded)
    // or 9 (both rows expanded).
    props.moveRow(dragIndex, hoverIndex, props.dragSection);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  },
  canDrop(
    props: { index: number; moveRow: MoveRow; dragSection?: string },
    monitor: DropTargetMonitor
  ) {
    if (props.dragSection) {
      return monitor.getItem().dragSection === props.dragSection;
    }

    return true;
  },
};

const DraggableRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
}))(
  DragSource('row', rowSource, connect => ({
    connectDragSource: connect.dragSource(),
  }))(BodyRow)
);

export default DraggableRow;
